<template>
	<div  id="Editorial-Info" class="main-wrap Editorial-wrap">
		<div class="flex between w100">
			<div class="left-box" v-if="step"><MainMenu :title="step==1?'编委初评':'终评'" :list="[{title:L(step==1?'初评入口':'终评入口'),path:'/Editorial/AwardTypeList/'+step},{title:L('评选说明'),path:'/Editorial/Info/'+step}]"/></div>
			
			<div class="w850 right-box" style="margin:unset">
				<template v-if="step == 1">
					<div class="title-h2">INITIAL ASSESSMENT DATE</div>
					<div class="title-h1">{{L("初评日期")}}</div>

					<div class="content">
						<p>{{L("征稿截止后的1~2周内（为期五天）")}}</p>
						<!-- <p>{{L("2022年6月16日–6月20日（为期五天）")}}</p> -->
					</div>
					
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					
					
					<div class="title-h2">SELECTION PROCESS</div>
					<div class="title-h1">{{L("评选流程")}}</div>
					
					<div class="flowBox">
						<div class="list">
							<div class="item" v-html="L('选择<br/>评选类别')"></div>
							<div class="arrow"></div>
							<div class="item" v-html="L('对作品<br/>进行打分')"></div>
							<div class="arrow"></div>
							<div class="item" v-html="L('确认<br/>评选清单')"></div>
							<div class="arrow"></div>
							<div class="item" v-html="L('提交<br/>评选结果')"></div>
						</div>
					</div>
					
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					
					<div class="info-list">
						<ol class="ol-zero">
							<li>
								<p class="b">{{L("指定账号登录")}}</p>
								<p>{{L("以亚太编辑部指定的账号登录。")}}</p>
							</li>
							<li>
								<p class="b">{{L("选择评选类别")}}</p>
								<p>{{L("点击每一个类别进行作品评选。")}}</p>
							</li>
							<li>
								<p class="b">{{L("投稿作品打分")}}</p>
								<p v-html='L("查看作品后，对每个类别的作品进行打分。<br/>（共有0、3、5、7四个分数级别可供选择）")'></p>
							</li>
							<li>
								<p class="b">{{L("选择推荐作品")}}</p>
								<p v-html='L("每个类别的特邀编委拥有推荐特权，可按一定比例<br/>选出直接收录到“年度推荐单元”的作品。")'></p>
							</li>
							<li>
								<p class="b">{{L("确认评选清单")}}</p>
								<p>{{L("确认评选清单无误后，提交评选结果。")}}</p>
							</li>
						</ol>
						
					</div>
				</template>
				<template v-if="step == 2">
					<div class="title-h2">FINAL ASSESSMENT DATE</div>
					<div class="title-h1">{{L("终评日期")}}</div>
					
					<div class="content">
						<p>{{L("初评后的1~2周内（为期两天）")}}</p>
					</div>
					
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					
					
					<div class="title-h2">FINAL ASSESSMENT PROCESS</div>
					<div class="title-h1">{{L("终选流程")}}</div>
					
					<div class="flowBox">
						<div class="list">
							<div class="item" v-html="L('13位评委<br/>一人20票')"></div>
							<div class="arrow"></div>
							<div class="item s" v-html="L('在所有推荐作<br/>品中投票选出<br/>20件最佳作品')"></div>
							<div class="arrow"></div>
							<div class="item" v-html="L('确认<br/>投票清单')"></div>
							<div class="arrow"></div>
							<div class="item" v-html="L('提交<br/>投票结果')"></div>
						</div>
					</div>
					
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					<div class="mt-20"></div>
					
					<div class="info-list">
						<ol class="ol-zero">
							<li>
								<p class="b">{{L("指定账号登录")}}</p>
								<p>{{L("以亚太编辑部指定的账号登录。")}}</p>
							</li>
							<li>
								<p class="b">{{L("投票选出20件最佳作品")}}</p>
								<p>{{L("在所有得到推荐的作品中投票选出20件最佳设计作品，并提交评选结果。")}}</p>
							</li>
							<li>
								<p class="b">{{L("提交投票结果")}}</p>
								<p>{{L("最终入选“年度主题单元”的作品将有得票数量决定。")}}</p>
							</li>
						</ol>
						
					</div>
				</template>
				
				<template v-if="step == 0">
					<div class="ta-c">
						<div class="title-h1">{{L('评审通道暂未开放，请联系管理员。')}}</div>
					</div>
				</template>
				<div class="mt-20"></div>
				<div class="mt-20"></div>
			</div>
		</div>	
	</div>
</template>
<script>
import MainMenu from '/src/views/MainMenu';
export default {
  name: 'Editorial-Info',
  components: {
    MainMenu
  },
  data(){
    return {
			step:parseInt(this.$route.params.step) || 0,
    };
  },
	watch:{
		async $route(){
			this.step = parseInt(this.$route.params.step) || 0
			this.$root.checkEditorialStage(this.step);
		}
	},
  async mounted () {
		this.$root.checkEditorialStage(this.step);
		
  },
  methods: {
		
  }
}
</script>

<style scoped lang="less">
#Editorial-Info{
	font-size:14px;
	.content{
		
	}
	
	.left-box{
		// position: absolute;
		flex-shrink: 0;
		// left:45px;
		width:150px;
	}
}


</style>